#root {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
}

.main {
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
}

.course-title {
  margin-top: 30px;
  font-size: 28px;
  margin-bottom: 0;
  text-transform: uppercase;
  padding: 0 10px;
  font-weight: 600;
  color: var(--c2);
}

.category-title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
}
.category-box {
  margin-bottom: 20px;
}

.products {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.product {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.product .title {
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.product .description {
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.product .img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  flex-shrink: 0;
}

.product .img img {
  max-width: 100%;
  max-height: 100%;
}

.product .price {
  color: var(--color-primary);
  font-weight: 900;
  font-size: 18px;
  white-space: nowrap;
}
.category {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}
.description p {
  font-weight: 300;
  font-size: 14px;
}

.sheet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #00000095;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sheet .content {
  background-color: #fff;
  border-radius: 10px;
  max-height: 100%;
  overflow: auto;
  padding: 15px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
}

.sheet .scroll-content {
  flex-grow: 1;
  overflow: auto;
}

.sheet button {
  border: 0;
  background: var(--c2);
  color: var(--c2c);
  text-align: left;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sheet .img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  margin-bottom: 20px;
}

.sheet .img img {
  max-width: 100%;
  max-height: 100%;
}

.sheet .title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.sheet .description {
  font-size: 14px;
  margin: 15px 0;
  text-align: center;
}

.sheet .info-box {
  margin-bottom: 20px;
}

.sheet .info-box-content {
  font-size: 14px;
}

.sheet .info-box-content.ingredients {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.sheet .info-box-content.allergens {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sheet .subtitle {
  font-weight: bold;
  margin-top: 5px;
}
.sheet h5.subtitle {
  color: var(--c1);
}
.sheet div.subtitle {
  border-top: 1px solid #ccc;
  padding-top: 5px;
}

.tags {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.tags > div {
  color: var(--c1);
  background-color: var(--c1o);
  font-size: var(--extrasmall);
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.product .tags:has(div) {
  margin: 5px 0;
}

.allergens > * {
  display: flex;
  align-items: center;
  gap: 5px;
}

.allergens .img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 3px;
  margin: 0;
}
.allergens svg {
  width: 30px;
  height: 30px;
}
