.header {
	background-color: var(--c1);
	color: var(--c1c);
	display: grid;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	grid-template-columns: 90px auto 90px;
}

.header .left {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
}

.header .logo {
	flex-grow: 1;
	text-align: center;
	display: block;
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
}

.header .right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
}

.header .logo img {
	max-height: 40px;
	max-width: 100%;
}

.header .button {
	background-color: var(--c2);
	color: var(--c2c);
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	width: 40px;
	height: 40px;
	padding: 0px;
}

.subheader {
	font-size: 12px;
	background-color: var(--c2);
	color: var(--c2c);
	padding: 5px;
	text-align: center;
	border-top: 1px solid #fff;
}
