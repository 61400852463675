@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
	--c1: #494373;
	--c1c: #fff;
	--c2: #d50000;
	--c2c: #fff;
	--header-h: 60px;
}

body {
	font-family: "Poppins", sans-serif !important;
}

.buttons-groups {
	display: flex;
	gap: 10px;
	align-items: center;
}
.buttons-groups h3 {
	text-transform: uppercase;
	font-weight: 600;
}

.buttons-groups.right {
	justify-content: flex-end;
}

.buttons-groups.justify {
	justify-content: space-between;
}
.spin-loading {
	-webkit-animation: fa-spin 2s linear infinite;
	animation: fa-spin 2s linear infinite;
}

.lock {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	padding: 10px;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #00000095;
	padding: 10px;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading .content {
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	padding: 10px;
	border-radius: 5px;
}

/* SHOP */
.shops {
	text-align: center;
}
.shops button {
	background: var(--c1);
	color: var(--c1c);
	margin-bottom: 10px;
	border: unset;
	font-size: 22px;
	width: 100%;
	min-height: 100px;
	border-radius: 5px;
}
.shops button h3 {
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 0px;
}
.shops button span {
	font-size: 12px;
	display: block;
}

#menu {
	background-color: #f7f7f7;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 250px;
	padding-bottom: 10px;
	height: calc(100vh - 20px);
	border-right: 1px solid var(--c1);
	box-shadow: 5px 0px 10px #00000090, 0px 5px 10px #00000090;
	border-bottom-right-radius: 10px;
	display: flex;
	flex-direction: column;
}

#menu .scroll-content {
	max-height: 100%;
	overflow: auto;
}

#menu .menu-header {
	background-color: var(--c1);
	display: flex;
	justify-content: flex-end;
	padding: 10px;
}

#menu .menu-header button {
	border: 0;
	background: var(--c2);
	color: var(--c2c);
	text-align: left;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#menu .powered {
	font-size: 14px;
	text-align: center;
	border-top: 1px solid #000000;
	padding: 10px;
	margin-top: 10px;
}

#menu .powered a {
	color: #333;
	text-decoration: none;
}

#menu .powered a:hover {
	text-decoration: underline;
}

.page-title {
	margin: 5px 0;
	text-align: center;
}

.menu-categories {
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	text-transform: uppercase;
	font-weight: 600;
}
.menu-categories .macrocourse-name {
	color: var(--c2);
}
.menu-categories h5 {
	text-transform: uppercase;
	font-weight: 600;
	text-align: left;
}

.menu-categories button {
	color: var(--c1);
	background-color: var(--c1c);
	border: 0;
	padding: 0;
	text-decoration: none;
	text-align: left;
	font-size: 16px;
	background-color: transparent;
	margin: 0;
	display: block;
	text-transform: uppercase;
	padding: 5px;
	border-radius: 5px;
}

.menu-categories button:hover {
	text-decoration: underline;
}

.menu-categories button.active {
	font-weight: bold;
	color: var(--c1c);
	background-color: var(--c1);
}

.categories-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.categories-list > div {
	padding: 10px;
	flex-basis: 50%;
}

@media (min-width: 768px) {
	.categories-list > div {
		flex-basis: 33%;
	}
}

@media (min-width: 1080px) {
	.categories-list > div {
		flex-basis: 25%;
	}
}

.categories-list button {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	padding: 10px;
	background-color: #fff;
	border: 1px solid #eee;
	border-radius: 5px;
	width: 100%;
}

.categories-list button.bg-colored {
	color: var(--c2c);
	background-color: var(--c2);
}

.categories-list button .img {
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (min-width: 768px) {
	.categories-list button .img {
		height: 150px;
	}
}

.categories-list button .img img {
	display: block;
	width: 100%;
	max-height: 100%;
}

.price-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
}

.footer {
}

.footer-content {
	text-align: center;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #000;
	padding: 10px;
	font-size: 12px;
}

.footer a {
	text-decoration: none;
	color: #fff;
}

.footer a:hover {
	text-decoration: underline;
}

.footer-buttons {
	display: flex;
	width: 100%;
}

.footer-buttons > button {
	width: 100%;
	border: 0;
	background-color: var(--c1);
	color: var(--c1c);
	padding: 10px;
	font-size: 14px;
}

.footer-buttons > button > i {
	font-size: 20px;
}

.footer-buttons > button > div {
	text-align: center;
}

.select-languages {
	position: relative;
}

.select-languages button {
	display: flex;
	gap: 5px;
	align-items: center;
	background-color: #fff;
	padding: 0px 10px;
	border: 1px solid #eee;
	font-size: 16px;
	border-radius: 5px;
	height: 39px;
}

.select-languages button img {
	height: 20px;
}

.select-languages .submenu {
	position: absolute;
	right: 0;
	top: 45px;
	background-color: #fff;
	padding: 10px;
	border-radius: 5px;
	z-index: 1;
	border: 1px solid #eee;
	display: flex;
	flex-direction: column;
	gap: 5px;
}
