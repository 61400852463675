.search {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: #00000095;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.search .content {
	background-color: #fff;
	border-radius: 10px;
	max-height: 100%;
	overflow: auto;
	padding: 15px;
	width: 100%;
	max-width: 800px;
	display: flex;
	flex-direction: column;
}

.search .scroll-content {
	flex-grow: 1;
	overflow: auto;
}

.search button {
	border: 0;
	background: var(--c2);
	color: var(--c2c);
	text-align: left;
	padding: 5px 10px;
	border-radius: 5px;
	margin-bottom: 10px;
}
