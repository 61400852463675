.menu-categories-2 {
  overflow: auto;
  border-bottom: 1px solid black;
  flex-shrink: 0;
  flex-grow: 0;
}

.menu-categories-2 .scroll-content {
  padding: 5px;
  display: flex;
  gap: 5px;
  overflow-x: auto;
}

.menu-categories-2 button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid var(--c1);
  color: var(--c1);
  background-color: var(--c1c);
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.menu-categories-2 button.active {
  border: 1px solid var(--c1);
  color: var(--c1c);
  background-color: var(--c1);
}

/* width */
.menu-categories-2 .scroll-content::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
.menu-categories-2 .scroll-content::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
.menu-categories-2 .scroll-content::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
.menu-categories-2 .scroll-content::-webkit-scrollbar-thumb:hover {
  background: rgb(143, 143, 143);
}
